<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab x-small class="ml-1" color="info">
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span v-text="'Editar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'CIERRE DE AÑO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Activo"
                    :value="data.closure ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="A partir de"
                    :value="data.closure_start_date"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="F. recepción"
                    :value="data.closure_reception_date"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Red proveedores"
                    :value="data.closure_provider_brand.provider_brand"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="12">
                  <ViewData
                    label="Excepto"
                    :value="data.closure_exception_providers"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken } from "@/control";
import FaqDlg from "@/components/FaqDlg.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    ViewData
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      ldg: false,
      data: {
        closure: true,
        closure_start_date: "2024-12-10 10:00:00",
        closure_reception_date: "2025-01-02 09:00:00",
        closure_provider_brand_id: 3,
        closure_provider_brand: {
          id: 3,
          provider_brand: "ABC CONCENTRADOR",
        },
        closure_exception_providers: "MEDICA SUR",
      },
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.ldg = true;
      this.data = null;

      Axios.get(URL_API + "/config", headersToken(this.login.token)).then(
        (res) => {
          this.data = res.data.data;
          this.ldg = false;
        }
      );
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    //this.getData();
  },
};
</script>